import React from 'react'
import { Link } from 'react-router-dom';

export default function Navbar() {

    return (
        <>
            {/* <!-- Navbar --> */}
            <nav className=" text-dark m-0 p-0 w-100 navbar navbar-expand-lg navbar-light bg-light shadow">
                {/* <!-- Container wrapper --> */}
                <div className="m-0 p-0  container-fluid">

                    <Link to={'/'} className="navbar-brand " >
                        <img className='m-auto aline-middle p-2 px-lg-4 px-2' src={require("../img/INDIVM_LOGO.png")} alt="" style={{ objectFit: 'cover', height: '70px' }} />
                    </Link>
                    {/* <!-- Collapsible wrapper --> */}
                    <div className='d-lg-block d-none' style={{ maxHeight: '80px' }}>
                        {/* <!-- Navbar brand --> */}

                        {/* <!-- Left links --> */}
                        <ul className="text-center navbar-nav me-auto mb-2 mb-lg-0" style={{ fontSize: '17px', color: 'black' }}>
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link mx-2 contact fw-bold ">
                                    <span className='activeButtonDash '><i className="fa-solid fa-minus fa-lg"></i></span>  Home </Link>

                            </li>
                            <li className="nav-item">
                                <Link className="nav-link mx-2 contact fw-bold" to="/about">
                                    <span className='activeButtonDash'><i className="fa-solid fa-minus fa-lg"></i></span>  About Us </Link>

                            </li>

                            <li className="nav-item">
                                <div className="nav-link mx-2 contact fw-bold" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className='activeButtonDash ' ><i className="fa-solid fa-minus fa-lg"></i></span>  Products </div>
                                <div className="dropdown text-center ">
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link to={'/solarPanel'} className="dropdown-item" >Solar Panel</Link></li>
                                        <li><Link to={'/liBattery'} className="dropdown-item" >Lithium Battery</Link></li>
                                        <li><Link to={'/solarWaterHeater'} className="dropdown-item" >Solar Water Heater</Link></li>

                                    </ul>
                                </div>
                            </li>
                            {/* <li className="nav-item">
                                <div className="nav-link mx-2 contact fw-bold" id="dropdownMenuButton2  " data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className='activeButtonDash ' ><i className="fa-solid fa-minus fa-lg"></i></span>  Gallery </div>
                                <div className="dropdown text-center ">
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2  ">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </li> */}


                            <li className="nav-item">
                                <Link className="nav-link mx-2 contact fw-bold" to="/gallery">
                                    <span className='activeButtonDash'><i className="fa-solid fa-minus fa-lg"></i></span>  Gallery </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link mx-2 contact fw-bold" to="/contact">
                                    <span className='activeButtonDash'><i className="fa-solid fa-minus fa-lg"></i></span>  Contacts </Link>
                            </li>
                        </ul>
                        {/* <!-- Left links --> */}
                    </div>
                    {/* <!-- Collapsible wrapper --> */}


                    {/* <!-- Right elements --> */}
                    <div className='d-flex d-lg-none justify-content-center align-items-center ' style={{ backgroundColor: '#ed793f', height: '80px', width: '80px' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        {/* <!-- Avatar --> */}

                        <i className="fa-sharp text-center text-white fa-solid fa-bars fs-3"></i>

                    </div>
                    <div className="d-lg-flex d-none align-items-center " style={{ backgroundColor: '#20242b', height: '80px' }}>
                        <a href='https://www.facebook.com' className="text-white socialIcons-fb fs-5 d-flex align-items-center mx-2 justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                            <i className="fa-brands fa-facebook"></i>
                        </a>
                        <a href='' className="socialIcons-call text-white fs-6 mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                            <i className="fa-solid fa-phone"></i>
                        </a>
                        <div className="socialIcons-whatsapp fs-5 text-white mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                            <i className="fa-brands fa-whatsapp"></i>
                        </div>
                        <div className="socialIcons-instagram fs-5 text-white mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                            <i className=" fa-brands fa-instagram"></i>
                        </div>
                    </div>



                    <div className="offcanvas d-lg-none  offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabe" style={{ width: '60%' }}>
                        <div className="offcanvas-header">
                            <Link to={'/'} className="navbar-brand " >
                                <img className='m-auto aline-middle p-2 px-lg-4 px-2' src={require("../img/INDIVM_LOGO.png")} alt="" style={{ objectFit: 'cover', height: '70px' }} />
                            </Link>
                            <button type="button" className="btn-close " data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <div className='d-flex justify-content-between fw-bold fs-4'>
                                <div > <Link to={'/'} className='text-dark fw-bold '> Home</Link></div>
                            </div>
                            <a className="text-dark" data-bs-toggle="collapse" href="#collapseProducts" role="button" aria-expanded="false" aria-controls="collapseProducts" >
                                <div className='d-flex justify-content-between fw-bold fs-4'>
                                    <div className=''> Products</div>
                                    <div>
                                        <i className="fa-solid fa-angle-down"></i>
                                    </div>

                                </div>
                            </a>
                            <div className="collapse" id="collapseProducts">
                                <div className="card card-body">
                                    <Link className='text-dark my-2 fw-bold' to={'/solarPanel'}>Solar Panel</Link>
                                    <Link className='text-dark my-2 fw-bold' to={'/liBattery'}>Lithium Battery</Link>
                                    <Link className='text-dark my-2 fw-bold' to={'/solarWaterHeater'}>Solar Water Heater</Link>

                                </div>
                            </div>
                            {/* <a className="text-dark" data-bs-toggle="collapse" href="#collapseGallergy" role="button" aria-expanded="false" aria-controls="collapseGallergy" >
                            <div className='d-flex justify-content-between fw-bold fs-4'>
                                <div> Gallery</div>
                                <div>
                                    <i className="fa-solid fa-angle-down"></i>
                                </div>
                            </div>
                            </a>
                            <div className="collapse" id="collapseGallergy">
                                <div className="card card-body">
                                    Gallery
                                    Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
                                </div>
                            </div> */}
                            <div className='fw-bold fs-4' > <Link to={'/about'} className='text-dark '> About Us</Link></div>
                            <div className='fw-bold fs-4'> <Link to={'/gallery'} className='text-dark '> Gallery</Link></div>
                            <div className='fw-bold fs-4'> <Link to={'/contact'} className='text-dark '> Contact Us</Link></div>
                            <div className="">
                                <div className="d-lg-none d-flex align-items-center my-5 rounded " style={{ backgroundColor: '#20242b', height: '80px', }}>
                                    <a href='https://www.facebook.com' className="text-white socialIcons-fb fs-5 d-flex align-items-center mx-2 justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                        <i className="fa-brands fa-facebook"></i>
                                    </a>
                                    <a href='tel:+919518220044' className="socialIcons-call text-white fs-6 mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                        <i className="fa-solid fa-phone"></i>
                                    </a>
                                    <a target={'_blank'} href='https://wa.me/+919518220044' className="socialIcons-whatsapp fs-5 text-white mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                        <i className="fa-brands fa-whatsapp"></i>
                                    </a>
                                    <div className="socialIcons-instagram fs-5 text-white mx-2 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                        <i className=" fa-brands fa-instagram"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>



                    {/* <!-- Right elements --> */}
                </div>
                {/* <!-- Container wrapper --> */}
            </nav>
            {/* <!-- Navbar --> */}


        </>
    )
}
