import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function AboutUs() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    return (
        <>
            {/* intro */}
            <div className="contactUsIntro pt-lg-5" onLoad={scrollToTop}>
                <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>About Us</div>
                <div className="fs-6 text-white  d-flex align-items-center justify-content-center">
                    <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i> &nbsp; &nbsp; About
                </div>
            </div>
            <div className="container my-lg-5 d-block">
                <div className=" row mb-5">

                    <div className="col-lg-6">
                        <span className='about' style={{ fontSize: '150px', color: 'white' }}>ABOUT</span>

                        <div className='aboutContainer'>
                            <div style={{ color: '#57b33e', fontSize: "18px" }}>| welcome to IEPL</div>
                            <div className="h1 fw-bold" style={{ color: "#232323", fontSize: '36px', }}>WHO WE ARE
                            </div>

                            <div className="pe-3 py-4 text" style={{ textAlign: 'justify' }}>Indivm Energy Private Limited is an organization with a goal of contributing efforts to come out of dark polluted human lifestyle. Harnessing Renewable Green energy to the humanity is our moto.  So we are experienced professionals focused on producing quality work and making a significant impact. We enjoy applying our career experience and skills to managing projects through creative problem solving and innovative thinking. We possess a strong eye for detail and can thrive independently as well as in a team environment. We are confident we have what it takes to make a significant contribution to any business we join, and are always striving to develop and expand our professional skillset. Want to know more about our qualifications? Read about our journey right here.

                                The Company came in to existence in April Month of year 2017.  Now working in PAN India dedicated to Industrial Solar Projects.</div>
                            <div className='d-flex flex-wrap py-3'>
                                <div>
                                    <Link to={'/contact'} className='learnMoreBtn '>Free Consultancy</Link>
                                </div>
                                <div className="d-flex align-items-center justify-content-center px-2">
                                    <a target={'_blank'} href='https://wa.me/+919518220044' className='text-black border-0 contactWhatsappBtn' style={{ backgroundColor: 'inherit', fontWeight: 'bold' }}><i className="fa-brands fa-whatsapp fa-lg text-success"></i> Contact on WhatsApp</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=" col-lg-6 col-md-6 aboutUsCol2">

                        <img className='enhancedServices' src={require("../img/Enhanced Services.png")} alt="" />
                        <img className='man-worker-firld-by-solar' src={require("../img/man-worker-firld-by-solar.png")} alt="" />
                        <img className='group-people-working-out-business-plan-office' src={require("../img/group-people-working-out-business-plan-office.png")} alt="" />
                        <img className='maintenanceServices' src={require("../img/Maintenance Services.png")} alt="" />
                    </div>

                </div>
            </div>

        </>
    )
}
