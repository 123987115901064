import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'

export default function ContactUs() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    return (
        <>
            {/* intro */}
            <div onLoad={scrollToTop}> 
            <div className="contactUsIntro pt-lg-5" >
                <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Contacts</div>
                <div className="fs-6 text-white  d-flex align-items-center justify-content-center">
                    <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i> &nbsp; &nbsp; Contacts
                </div>
            </div>
            <span className='contacts-lg' style={{ color: 'white' }}>CONTACT</span>
            <div className="contactUsBody">
                <div className="row m-3 m-md-5 p-3   p-md-5">
                    <div className="col-12 col-lg-3">
                        <h6 style={{ color: '#6fb14f' }}>| get in touch</h6>
                        <h2 className='fw-bold'>Contacts</h2>
                        <div className="d-flex ">
                            <div className="d-flex align-items-center justify-content-center">
                                <i class="fa-solid fs-3 fa-location-dot" style={{ color: '#ed793f' }}></i>
                            </div>
                            <div className="p-2 " style={{ fontSize: '17px' }}>Sonepat Ganaur Road, Opposite Sakar Kui Mandir Sonepat - 131001</div>
                        </div>
                        <div className="d-flex ">
                            <div className="d-flex align-items-center justify-content-center">
                                <i class="fa-solid fs-3 fa-at" style={{ color: '#ed793f' }}></i>
                            </div>
                            <div className="p-2 " style={{fontSize:'18px'}}>
                                <div className="email"> <a className='text-dark'  href="mailto:indivmenergy@gmail.com"> indivmenergy@gmail.com</a></div>
                                <div className="email"><a className='text-dark' href="mailto:indivmenergy@gmail.com"> indivmenergy@outlook.com</a></div>
                            </div>
                        </div>
                        <div className="d-flex ">
                            <div className="d-flex align-items-center justify-content-center">
                                <i class="fa-solid fs-3 fa-phone" style={{ color: '#ed793f' }}></i>
                            </div>
                            <a className='p-2 phone' style={{ fontSize: '18px', color: 'black' }} target={'_blank'} href='tel:+919518220044'>+91 9518220044</a>

                        </div>
                    </div>
                    <div className="col-lg-9 col-12 contactForm  bg-white p-lg-5 p-3">
                        <form action="https://formsubmit.co/vpnbeniwal123@gmail.com" method="POST" >
                            <h1 className=' ' >Send Inquiry</h1>

                            <input className='my-2 p-2 w-100' id='name' type="text" name="Name" placeholder='Name' required />
                            <input className='my-2 p-2 w-100' type="email" id='email' name="Email" placeholder='E-Mail adress' required />

                            <input className=' my-2 p-2 w-100' type="number" name="Number" id='phone' placeholder='Phone Number' required />
                            <textarea className=' my-2 p-2 w-100' id='message' type="message" name="Message" placeholder='How can we help you?' required />

                            <button className='border-0' type="submit" id='send'>Send</button>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            
        </>
    )
}
