import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function SolarWaterHeater() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div className="productSolarPanel pt-lg-5" onLoad={scrollToTop}>
                <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                <div className="fs-6 text-white  d-flex align-items-center justify-content-center">
                    <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i> &nbsp; &nbsp; Solar Water Heater
                </div>
            </div>
            <div className=" row m-md-5 m-2 ">
                <div className="col-lg-3 sideBarForSolarPanel">
                    <div className="fw-bold">
                        <h1 className="">Categories</h1>
                        <ul className=''>
                            <li><Link className='text-dark' to={'/solarPanel'}>Solar Panel</Link></li>
                            <li><Link className='text-dark' to={'/liBattery'}>Lithium Battery</Link></li>
                            <li><Link className='text-dark' to={'/solarWaterHeater'}>Solar Water Heater</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="d-lg-flex justify-content-start">

                        <div className=" ">
                            <div className="galleryRow">
                                <div className="galleryColumn">
                                    <Link className=" solarPanelCard  text-dark" to={'/heaterFPC'} >
                                        <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                            <img className='mx-5 my-3' src={require('../img/solarwaterheaterFPC.png')} alt="" />
                                        </div>
                                        <hr />
                                        <div className='fw-bold text-center p-2 py-3'>
                                            <div className='fs-5'>Solar Water Heater (FPC)</div>

                                        </div>
                                    </Link>

                                </div>
                                <div className="galleryColumn">
                                    <Link className=" solarPanelCard  text-dark" to={'/heaterETC'} >
                                        <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                            <img className='mx-5 my-3' src={require('../img/solarwaterheaterETC.png')} alt="" />
                                        </div>
                                        <hr />
                                        <div className='fw-bold text-center p-2 py-3'>
                                            <div className='fs-5'>Solar Water Heater (ETC)</div>

                                        </div>
                                    </Link>

                                </div>

                            </div>
                        </div>




                    </div>

                </div>
            </div>



        </>
    )
}
