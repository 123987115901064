import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Gallery() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div className="galleryIntro pt-lg-5" onLoad={scrollToTop}>
                <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Gallery</div>
                <div className="fs-6 text-white  d-flex align-items-center justify-content-center">
                    <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i> &nbsp; &nbsp; Gallery
                </div>
            </div>
            <div className="galleryRow">
                <div className="galleryColumn">
                    <img src={require('../img/gallery/1.jpg')} alt="" />
                    <img src={require('../img/gallery/2.jpg')} alt="" />
                    <img src={require('../img/gallery/3.jpg')} alt="" />
                    <img src={require('../img/gallery/4.jpg')} alt="" />
                    <img src={require('../img/gallery/5.jpg')} alt="" />
                    <img src={require('../img/gallery/35.jpg')} alt="" />
                    <img src={require('../img/gallery/36.jpg')} alt="" />
                    <img src={require('../img/gallery/37.jpg')} alt="" />
                    <img src={require('../img/gallery/38.jpg')} alt="" />
                    <img src={require('../img/gallery/24.jpg')} alt="" />
                    <img src={require('../img/gallery/41.jpg')} alt="" />

                </div>
                <div className="galleryColumn">
                    <img src={require('../img/gallery/5.jpg')} alt="" />
                    <img src={require('../img/gallery/6.jpg')} alt="" />
                    <img src={require('../img/gallery/7.jpg')} alt="" />
                    <img src={require('../img/gallery/8.jpg')} alt="" />
                    <img src={require('../img/gallery/9.jpg')} alt="" />
                    <img src={require('../img/gallery/30.jpg')} alt="" />
                    <img src={require('../img/gallery/31.jpg')} alt="" />
                    <img src={require('../img/gallery/32.jpg')} alt="" />
                    <img src={require('../img/gallery/33.jpg')} alt="" />
                    <img src={require('../img/gallery/34.jpg')} alt="" />
                    <img src={require('../img/gallery/42.jpg')} alt="" />
                </div>
                <div className="galleryColumn">
                    <img src={require('../img/gallery/10.jpg')} alt="" />
                    <img src={require('../img/gallery/11.jpg')} alt="" />
                    <img src={require('../img/gallery/12.jpg')} alt="" />
                    <img src={require('../img/gallery/13.jpg')} alt="" />
                    <img src={require('../img/gallery/14.jpg')} alt="" />
                    <img src={require('../img/gallery/25.jpg')} alt="" />
                    <img src={require('../img/gallery/26.jpg')} alt="" />
                    <img src={require('../img/gallery/27.jpg')} alt="" />
                    <img src={require('../img/gallery/28.jpg')} alt="" />
                    <img src={require('../img/gallery/29.jpg')} alt="" />
                    <img src={require('../img/gallery/43.jpg')} alt="" />

                </div>
                <div className="galleryColumn">
                    <img src={require('../img/gallery/23.jpg')} alt="" />
                    <img src={require('../img/gallery/15.jpg')} alt="" />
                    <img src={require('../img/gallery/16.jpg')} alt="" />
                    <img src={require('../img/gallery/17.jpg')} alt="" />
                    <img src={require('../img/gallery/18.jpg')} alt="" />
                    <img src={require('../img/gallery/19.jpg')} alt="" />
                    <img src={require('../img/gallery/20.jpg')} alt="" />
                    <img src={require('../img/gallery/21.jpg')} alt="" />
                </div>

            </div>



        </>
    )
}
