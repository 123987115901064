import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function LiLFP() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div onLoad={scrollToTop}>
                <div className="productsIntro pt-lg-5" >
                    <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                    <div className="fs-6 text-white text-center  d-md-flex align-items-center justify-content-center">
                      <div className="px-2">
                      <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                      </div>
                        <div className="px-2">
                        <Link className='text-white text-center' to={'/liBAttery'}>Li Battery</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className="px-2"> Lithium Battery (LFP)</div>
                    </div>
                </div>
                <div className="productBody row" >
                    <div className="col-lg-6 d-flex">
                        {/* <div className="productSmallImg ">
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                    </div> */}
                        <div className="productImg m-auto ">
                            <img className='w-100' id='productImage' alt="" src={require("../img/liLFP.jpeg")} />
                        </div></div>
                    <div className="col-lg-6 py-lg-4">
                        <h1 className='fw-bold'>Lithium Iron Phosphate (LFP) Battery</h1>

                        <div className='fs-5'>A Lithium Iron Phosphate (LFP) battery is a lithium-ion battery used in various applications. It is composed of lithium iron phosphate, an environmentally friendly compound. These batteries can charge and discharge at high speeds, making them ideal for applications requiring a lot of power. Due to their chemistry, they are also more stable and safer than other lithium batteries. This makes them an attractive option for electric vehicles, solar energy storage, and consumer electronics applications. LFP batteries offer many advantages over traditional lead-acid batteries, making them an attractive option for various applications.</div>
                        <hr className="w-25 text-muted bg-muted" />
                        <div className='fs-5'>

                            <div className='my-lg-3'>
                                <span className='fw-bold'>Catagory:</span> Li Battery
                            </div>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>Tags:</span> capacity, innovation, solar energy
                            </div>
                        </div>
                        <div className='my-3 my-lg-3'>
                            <Link to={"/contact"} className='text-white fw-bold px-3 py-2  getInquiry' style={{ backgroundColor: '#57b33e' }}>Get Inquiry</Link>
                        </div>
                    </div>
                </div>
                <div className="relatedProduct ">
                    <h2>Related Products</h2>
                    <div className="galleryRow">
                        <div className="galleryColumn">
                            <Link className=" solarPanelCard  text-dark" to={'/liNMC'} >
                                <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                    <img className='mx-5 my-3' src={require('../img/liNMC.jpeg')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>Li Battery (NMC)</div>

                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
