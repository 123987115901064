import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function HeaterETC() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div onLoad={scrollToTop}>
                <div className="productsIntro pt-lg-5" >
                    <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                    <div className="fs-6 text-white text-center d-md-flex align-items-center justify-content-center">
                        <div className='px-2'>
                            <Link className='text-white text-center' to={'/'}>Home</Link>&nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className='px-2'>
                            <Link className='text-white text-center' to={'/solarWaterHeater'}>Solar Water Heater</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className='px-2'> Solar Water Heater (ETC)
                        </div>
                    </div>
                </div>
                <div className="productBody row" >
                    <div className="col-lg-6 d-flex">
                        {/* <div className="productSmallImg ">
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                    </div> */}
                        <div className="productImg m-auto ">
                            <img className='w-100' id='productImage' alt="" src={require("../img/solarwaterheaterETC.jpeg")} />
                        </div></div>
                    <div className="col-lg-6 py-lg-4">
                        <h1 className='fw-bold'>Solar Water Heater (ETC)</h1>

                        <div className='fs-5'>
                            <p>An Evacuated Tube Collector (ETC) is a solar water heating system that is based on efficient solar collector technology. It uses conductive glass tubes to absorb heat from the sun, which is then transferred to the water or other circulating fluids.
                                <br />
                                An ETC consists of a number of glass tubes that are made of borosilicate or soda lime glass. These sealed glass tubes have copper rods within them that are thermally conductive. The glass tubes are supported by a frame and are arranged in a parallel pattern. Each tube is made up of a thick outer tube and a thin inner tube. The air between the inner and outer tubes is removed to create a vacuum. The name, “evacuated tube,” comes from this evacuation of air. The vacuum gives the system an insulating effect, making heat loss difficult. <br />
                                ETC solar water heaters are more modern and more efficient when compared to their peers because:
                                <br />

                            </p>
                        </div>
                        <hr className="w-25 text-muted bg-muted" />
                        <div className='fs-5'>

                            <div className='my-lg-3'>
                                <span className='fw-bold'>Catagory:</span> Solar Water Heater
                            </div>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>Tags:</span> capacity, innovation, solar energy
                            </div>
                        </div>
                        <div className='my-3 my-lg-3'>
                            <Link to={"/contact"} className='text-white fw-bold px-3 py-2  getInquiry' style={{ backgroundColor: '#57b33e' }}>Get Inquiry</Link>
                        </div>
                    </div>
                </div>
                <div className="relatedProduct ">
                    <h2>Related Products</h2>
                    <div className="galleryRow">
                        <div className="galleryColumn">
                            <Link className=" solarPanelCard  text-dark" to={'/heaterFPC'} >
                                <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                    <img className='mx-5 my-3' src={require('../img/solarwaterheaterFPC.jpeg')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>Solar Water Heater (FPC)</div>

                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
