import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function LiNMC() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div onLoad={scrollToTop}>
                <div className="productsIntro pt-lg-5" >
                    <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                    <div className="fs-6 text-white text-center d-md-flex align-items-center justify-content-center">
                        <div className="px-2">
                        <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className="px-2">
                        <Link className='text-white text-center' to={'/liBAttery'}>Li Battery</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className="px-2"> Lithium Battery (NMC)</div>
                    </div>
                </div>
                <div className="productBody row" >
                    <div className="col-lg-6 d-flex">
                        {/* <div className="productSmallImg ">
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                    </div> */}
                        <div className="productImg m-auto ">
                            <img className='w-100' id='productImage' alt="" src={require("../img/liNMC.jpeg")} />
                        </div></div>
                    <div className="col-lg-6 py-lg-4">
                        <h1 className='fw-bold'>Lithium Battery (NMC)</h1>

                        <div className='fs-5'>
                            <p>
                                A lithium-ion battery, also known as the Li-ion battery, is a type of secondary (rechargeable) battery composed of cells in which lithium ions move from the anode through an electrolyte to the cathode during discharge and back when charging.
                                <br />
                                There are several specific advantages to lithium-ion batteries. The most important advantages are their high cell voltage, high energy density, and no memory effect.
                                <br />
                                Nickel manganese cobalt (NMC) batteries contain a cathode made of a combination of nickel, manganese, and cobalt. NMC is one of the most successful cathode combinations in Li-ion systems. It can be tailored to serve as energy cells or power cells like Li-manganese. NMC batteries are used for power tools, e-bikes, and other electric powertrains.</p>
                        </div>
                        <hr className="w-25 text-muted bg-muted" />
                        <div className='fs-5'>

                            <div className='my-lg-3'>
                                <span className='fw-bold'>Catagory:</span> Li Battery
                            </div>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>Tags:</span> capacity, innovation, solar energy
                            </div>
                        </div>
                        <div className='my-3 my-lg-3'>
                            <Link to={"/contact"} className='text-white fw-bold px-3 py-2  getInquiry' style={{ backgroundColor: '#57b33e' }}>Get Inquiry</Link>
                        </div>
                    </div>
                </div>
                <div className="relatedProduct ">
                    <h2>Related Products</h2>
                    <div className="galleryRow">
                        <div className="galleryColumn">
                            <Link className=" solarPanelCard  text-dark" to={'/liLFP'} >
                                <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                    <img className='mx-5 my-3' src={require('../img/liLFP.jpeg')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>Li Battery (LFP)</div>
                                </div>
                            </Link>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
