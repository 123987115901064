import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <div className="footer text-white d-grid">
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="mb-2">
                            <img className='m-auto aline-middle px-lg-4 px-2' src={require("../img/INDIVM_LOGO.png")} alt="" style={{ objectFit: 'cover', height: '100px' }} />
                        </div>
                        <div className='bg-muted mb-lg-3 mx-2 mb-2' style={{ width: '190px', height: '2px', backgroundColor: '#808080' }}></div>
                        <div className="p-lg-3 p-2">
                            IEPL's extensive range of robust and reliable products backed by its cutting-edge R&D and more than two decades of expertise are designed to performance.
                        </div>
                        <div className="d-flex">
                            <a  href='https://www.facebook.com' className="text-white socialIcons-fb fs-5 d-flex align-items-center mx-2 justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                            <a href='' className="socialIcons-call text-white fs-6 d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                <i className="fa-solid fa-phone"></i>
                            </a>
                            <div className="socialIcons-whatsapp fs-5 text-white d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                <i className="fa-brands fa-whatsapp"></i>
                            </div>
                            <div className="socialIcons-instagram fs-5 text-white d-flex align-items-center  justify-content-center " style={{ width: '38px', height: '38px', borderRadius: '50%' }}>
                                <i className=" fa-brands fa-instagram"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 m-lg-0 my-3 text-white" >
                        <h3 className='my-2' >Our Address
                        </h3>
                        <div className='my-3 bg-muted mb-lg-3 mb-2' style={{ width: '190px', height: '2px', backgroundColor: '#808080' }}></div>

                        <div className='my-lg-2' ><a className='text-white' href="#">
                            <span className='font-weight-bold'>
                                Indivm Energy Private Limited </span> <br />
                            <FontAwesomeIcon icon={faLocationDot}>
                            </FontAwesomeIcon> &nbsp;
                            Sonepat Ganaur Road, Opposite Sakar Kui Mandir Sonepat - 131001 <br /> </a></div>
                        <div className='my-lg-2' ><a className='text-white' href="tel:+91 9518220044"> <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> &nbsp; +91 9518220044 </a></div>
                        <div className='my-lg-2' ><a className='text-white' href="mailto:indivmenergy@gmail.com"> <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp; indivmenergy@gmail.com</a></div>
                        <div className='my-lg-2' ><a className='text-white' href="mailto:indivmenergy@gmail.com"> <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> &nbsp; indivmenergy@outlook.com</a></div>


                    </div>
                    <div className="col-lg-4 col-12 m-lg-0 my-3  " >
                        <h3 className='my-2' >Our Products
                        </h3>
                        <div className='my-3 bg-muted mb-lg-3 mb-2' style={{ width: '190px', height: '2px', backgroundColor: '#808080' }}></div>

                        <div className='my-lg-2' ><Link className='text-white' to="/solarmodule"> <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;  Solar PV Module</Link></div>
                        <div className='my-lg-2' ><Link className='text-white' to="/libattery"> <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;  Lithium Batteries</Link></div>
                        <div className='my-lg-2' ><Link className='text-white' to="/inverter"> <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp; Hybrid Inverters</Link></div>
                        <div className='my-lg-2' ><Link className='text-white' to="/solarwaterheater"> <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon> &nbsp;  Solar Water Heater</Link></div>
                    </div>
                </div>
                <hr />
                <p>Copyright © 2022 IEPL. All Rights Reserved</p>
            </div>

        </>
    )
}
