import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from './components/Home';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';

import ContactUs from './components/ContactUs';
import ScrollButton from './components/ScrollButton';
import WhatsAppBtn from './components/WhatsAppBtn';
import ProductPC66 from './components/ProductPC66';
import ProductPC72 from './components/ProductPC72';
import ProductMC66 from './components/ProductMC66';
import ProductMC144 from './components/ProductMC144';
import Gallery from './components/Gallery';
import SolarPanel from './components/SolarPanel';
import SolarWaterHeater from './components/SolarWaterHeater';
import HeaterETC from './components/HeaterETC';
import HeaterFPC from './components/HeaterFPC';
import LiBattery from './components/LiBattery';
import LiNMC from './components/LiNMC';
import LiLFP from './components/LiLFP';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <ScrollButton />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/productPC66" element={<ProductPC66 />} />
          <Route path="/productPC72" element={<ProductPC72 />} />
          <Route path="/productMC66" element={<ProductMC66 />} />
          <Route path="/productMC144" element={<ProductMC144 />} />
          <Route path="/solarPanel" element={<SolarPanel />} />
          <Route path="/solarWaterHeater" element={<SolarWaterHeater />} />
          <Route path="/heaterETC" element={<HeaterETC />} />
          <Route path="/heaterFPC" element={<HeaterFPC />} />
          <Route path="liBattery" element={<LiBattery />} />
          <Route path="liNMC" element={<LiNMC />} />
          <Route path="liLFP" element={<LiLFP />} />
          <Route path="/solarPanel" element={<SolarPanel />} />
          <Route path="/gallery" element={<Gallery />} />

        </Routes>
        <Footer />
        <WhatsAppBtn />
      </div>
    </BrowserRouter>
  );
}

export default App;
