import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function SolarPanel() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            <div onLoad={scrollToTop}> 
            <div className="productSolarPanel pt-lg-5" >
                <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                <div className="fs-6 text-white  d-flex align-items-center justify-content-center">
                    <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i> &nbsp; &nbsp; Solar Panel
                </div>
            </div>

            <div className="row m-md-5 m-2">
                <div className="col-md-3 sideBarForSolarPanel">
                    <div className="fw-bold">
                        <h1 className="">Categories</h1>
                        <ul className=''>
                            <li><Link className='text-dark' to={'/solarPanel'}>Solar Panel</Link></li>
                            <li><Link className='text-dark' to={'/liBattery'}>Lithium Battery</Link></li>
                            <li><Link className='text-dark' to={'/solarWaterHeater'}>Solar Water Heater</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="">
                        <div className="galleryRow">
                            <div className="galleryColumn">
                                <Link className=" solarPanelCard  text-dark" to={'/productPC66'} >
                                    <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                        <img className='mx-5 my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                                    </div>
                                    <hr />
                                    <div className='fw-bold text-center p-2 py-3'>
                                        <div className='fs-5'>PolyCrystalline 66Cell</div>

                                    </div>
                                </Link>

                            </div>
                            <div className="galleryColumn">
                                <Link className=" solarPanelCard  text-dark" to={'/productPC72'}>
                                    <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                        <img className='mx-auto my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                                    </div>
                                    <hr />
                                    <div className='fw-bold text-center p-2 py-3'>
                                        <div className='fs-5'>PolyCrystalline 72 Cell</div>

                                    </div>
                                </Link>
                            </div>
                            <div className="galleryColumn">

                                <Link className="relatedProductImg solarPanelCard  text-dark" to={'/productMC66'}   >
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <img className='mx-auto my-3' src={require('../img/solarPanelMC72.png')} alt="" />
                                    </div>
                                    <hr />
                                    <div className='fw-bold text-center p-2 py-3'>
                                        <div className='fs-5'>MonoCrystalline 72Cell</div>

                                    </div>
                                </Link>
                            </div>
                            <div className="galleryColumn">

                                <Link className="relatedProductImg solarPanelCard  text-dark" to={'/productMC144'}   >
                                    <div className=" d-flex align-items-center justify-content-center">
                                        <img className='mx-auto my-3' src={require('../img/solarPanelMC144.png')} alt="" />
                                    </div>
                                    <hr />
                                    <div className='fw-bold text-center p-2 py-3'>
                                        <div className='fs-5'>MonoCrystalline 144Cell</div>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* <div className="solarPanelBody row">
                <div className="col-md-3 sideBarForSolarPanel">
                    <div className="fw-bold">
                        <h1 className="">Categories</h1>
                        <ul className=''>
                            <li><Link className='text-dark' to={'/solarPanel'}>Solar Panel</Link></li>
                            <li><Link className='text-dark' to={'/liBattery'}>Lithium Battery</Link></li>
                            <li><Link className='text-dark' to={'/solarWaterHeater'}>Solar Water Heater</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="d-lg-flex d-block justify-content-between">
                        <Link className=" solarPanelCard  text-dark" to={'/productPC66'} >
                            <div className="solarPanelCardImage d-flex align-items-center justify-content-center">
                                <img className='mx-5 my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                            </div>
                            <hr />
                            <div className='fw-bold text-center p-2 py-3'>
                                <div className='fs-5'>PolyCrystalline 66Cell</div>

                            </div>
                        </Link>
                        <Link className=" solarPanelCard  text-dark" to={'/productPC72'}>
                            <div className="solarPanelCardImage d-flex align-items-center justify-content-center">
                                <img className='mx-5 my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                            </div>
                            <hr />
                            <div className='fw-bold text-center p-2 py-3'>
                                <div className='fs-5'>PolyCrystalline 72Cell</div>

                            </div>
                        </Link>
                        <Link className=" solarPanelCard  text-dark" to={'/productMC66'}>
                            <div className="solarPanelCardImage d-flex align-items-center justify-content-center">
                                <img className='mx-5 my-3' src={require('../img/solarPanelMC72.jpeg')} alt="" />
                            </div>
                            <hr />
                            <div className='fw-bold text-center p-2 py-3'>
                                <div className='fs-5'>MonoCrystalline 66Cell</div>

                            </div>
                        </Link>
                        <Link className=" solarPanelCard  text-dark" to={'/productMC144'}   >
                            <div className="solarPanelCardImage d-flex align-items-center justify-content-center">
                                <img className='mx-5 my-3' src={require('../img/solarPanelMC144.jpeg')} alt="" />
                            </div>
                            <hr />
                            <div className='fw-bold text-center p-2 py-3'>
                                <div className='fs-5'>MonoCrystalline 144Cell</div>

                            </div>
                        </Link>


                    </div>

                </div>
            </div> */}



        </>
    )
}
