import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function ProductMC66() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    };
    // const myFunction=(smallImg)=>{
    //     let fullImg=document.getElementById('productImage');
    //     fullImg.src=smallImg.src;
    // }
    return (
        <>
            {/* intro */}
            
            <div onLoad={scrollToTop}>
                <div className="productsIntro pt-lg-5" >
                    <div className="pt-lg-5 text-white text-center pt-5 fw-bold" style={{ fontSize: '52px' }}>Products</div>
                    <div className="fs-6 text-white text-center  d-md-flex align-items-center justify-content-center">
                        <div className="px-2">
                        <Link className='text-white text-center' to={'/'}>Home</Link> &nbsp; &nbsp; <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div className="px-2">
                        <Link className='text-white text-center' to={'/solarPanel'}>Solar Panel <i className="fa-solid fa-angle-right"></i></Link>
                        </div>
                          <div className="px-2">Poly Crystalline 72Cell 390Wp</div>
                    </div>
                </div>
                <div className="productBody row" >
                    <div className="col-lg-6 d-flex">
                        {/* <div className="productSmallImg ">
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                        <img className='' alt="" src={require("../img/pngimg.com - solar_panel_PNG18.png")} />
                    </div> */}
                        <div className="productImg m-auto ">
                            <img className='' id='productImage' alt="" src={require("../img/solarPanelMC72.jpeg")} />
                        </div></div>
                    <div className="col-lg-6 py-lg-4">
                        <h1 className='fw-bold'>Solar Panel 72Cell 390Wp</h1>
                        <table className='fs-4 fw-bold ' style={{ color: '#6fb14f' }}>

                            <tr>
                                <td className='p-2' >DCR:</td>
                                <td className='p-2'> ₹11,415.00</td>
                            </tr>
                            <tr>
                                <td className='p-2'>NON DCR:</td>
                                <td className='p-2'>₹11,115.00</td>
                            </tr>

                        </table>
                        <div className='fs-5'>Our solar panels are low-profile and durable quietly converting sunlight to energy for decades to come. Integrated hardware and simple design achieve this by securing the panels close to your roof and to each other for a minimal aesthetic. (ALMM approved)</div>
                        <hr className="w-25 text-muted bg-muted" />
                        <div className='fs-5'>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>SKU:</span>solar-panel-300wp
                            </div>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>Catagory:</span> Solar Panels
                            </div>
                            <div className='my-lg-3'>
                                <span className='fw-bold'>Tags:</span> capacity, innovation, solar energy
                            </div>
                        </div>
                        <div className='my-3 my-lg-3'>
                            <Link to={"/contact"} className='text-white fw-bold px-3 py-2  getInquiry' style={{ backgroundColor: '#57b33e' }}>Get Inquiry</Link>
                        </div>
                    </div>
                </div>
                <div className="relatedProduct ">
                    <h2>Related Products</h2>
                    <div className="galleryRow">
                        <div className="galleryColumn">
                            <Link className=" solarPanelCard  text-dark" to={'/productPC66'} >
                                <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                    <img className='mx-5 my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>PolyCrystalline 66Cell</div>

                                </div>
                            </Link>

                        </div>
                        <div className="galleryColumn">
                            <Link className=" solarPanelCard  text-dark" to={'/productPC72'}>
                                <div className="relatedProductImg d-flex align-items-center justify-content-center">
                                    <img className='mx-auto my-3' src={require('../img/productPC66 IMG1.png')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>PolyCrystalline 72 Cell</div>

                                </div>
                            </Link>
                        </div>
                        <div className="galleryColumn">

                            <Link className="relatedProductImg solarPanelCard  text-dark" to={'/productMC144'}   >
                                <div className=" d-flex align-items-center justify-content-center">
                                    <img className='mx-auto my-3' src={require('../img/solarPanelMC144.png')} alt="" />
                                </div>
                                <hr />
                                <div className='fw-bold text-center p-2 py-3'>
                                    <div className='fs-5'>MonoCrystalline 144Cell</div>

                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
